import { pAdmin } from 'src/app/helpers/permission';
import { MenuItem } from 'src/app/models/menu-item';

export const MENU: MenuItem[] = [
  {
    title: 'menu.dashboard',
    route: '/home',
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: []
  },
  {
    title: 'menu.academic_year',
    route: '/academic',
    icon: '',
    svgIcon: 'admission',
    child: [],
    permissions: [pAdmin.academicYear.read]
  },
  {
    title: 'menu.school',
    route: '/schools',
    icon: '',
    svgIcon: 'school',
    child: [],
    permissions: [pAdmin.school.read]
  },
  {
    title: 'request_certificate.name',
    route: '/request-certificate',
    icon: '',
    svgIcon: 'listing',
    permissions: [pAdmin.requestCertificate.read],
    child: [
      {
        title: 'menu.request_certificate.officer_department',
        route: ['request-certificate/officer-department'],
        icon: '',
        svgIcon: '',
        permissions: [pAdmin.requestCertificate.officerDepartmentRead]
      },
      {
        title: 'menu.request_certificate.control_school',
        route: ['request-certificate/control-school-department'],
        icon: '',
        svgIcon: '',
        permissions: [pAdmin.requestCertificate.controlSchoolDepartment]
      },
      {
        title: 'menu.request_certificate.general_department',
        route: ['request-certificate/general-department'],
        icon: '',
        svgIcon: '',
        permissions: [pAdmin.requestCertificate.generalDepartmentRead]
      },
      {
        title: 'menu.request_certificate.ministry',
        route: ['request-certificate/ministry'],
        icon: '',
        svgIcon: '',
        permissions: [pAdmin.requestCertificate.ministryRead]
      },
      {
        title: 'menu.setting.degree',
        route: ['request-certificate/degree'],
        icon: '',
        svgIcon: '',
        permissions: [pAdmin.studentCertificate.read]
      }
    ]
  },
  {
    title: 'menu.human_resource.title',
    route: 'human-resource',
    icon: '',
    svgIcon: 'staff-alt',
    permissions: [pAdmin.staff.read],
    child: [
      {
        title: 'menu.human_resource.user',
        route: ['/human-resource/user'],
        icon: '',
        svgIcon: 'user',
        permissions: []
      },
      {
        title: 'menu.human_resource.lecturer',
        route: ['/human-resource/lecturer'],
        icon: '',
        svgIcon: 'lecturer',
        permissions: []
      }
    ]
  },
  {
    title: 'menu.student.title',
    route: 'student',
    icon: '',
    svgIcon: 'students-alt',
    permissions: [pAdmin.student.read],
    child: [
      {
        title: 'menu.student.student_list',
        route: ['/student/list'],
        icon: '',
        svgIcon: 'user',
        permissions: [pAdmin.student.read]
      },
      // {
      //   title: 'menu.student.graduated',
      //   route: ['/student/graduated'],
      //   icon: '',
      //   svgIcon: 'lecturer',
      //   permissions: [pAdmin.student.graduated]
      // }
    ]
  },
  {
    title: 'report.title',
    route: '/report',
    icon: '',
    svgIcon: 'report',
    child: [
      {
        title: 'លទ្ធផលសិក្សា',
        route: ['/report/student/request-certificate'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.certificateRequest],
      },
      {
        title: 'បញ្ជីដៃគូអភិវឌ្ឍន៍',
        route: ['/report/student/development-partner'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.developmentPartnerData],
      },
      {
        title: 'ចំនួនសិស្សវគ្គវែងតាមជំនាញ',
        route: ['/report/student/long-course'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.studentBySkill],
      },
      {
        title: 'ចំនួនសិស្សវគ្គខ្លីតាមជំនាញ',
        route: ['/report/student/short-course'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.shortCourseBySkill],
      },
      {
        title: 'ចំនួនសិស្សបញ្ចប់ការសិក្សា',
        route: ['/report/student/graduated'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.graduatedStudent],
      },
      {
        title: 'ចំនួនសិស្សជាប់ធ្លាក់',
        route: ['/report/student/pass-fail'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pAdmin.report.longCoursePassFailed],
      }
    ],
    permissions: []
  },
  {
    title: 'menu.feedback.title',
    route: '/feedback',
    icon: '',
    svgIcon: 'feedback',
    child: [
      {
        title: 'menu.feedback.student',
        route: ['/feedback/student'],
        icon: '',
        svgIcon: 'students',
        permissions: [pAdmin.feedback.adminRead]
      },
      {
        title: 'menu.feedback.staff',
        route: ['/feedback/staff'],
        icon: '',
        svgIcon: 'students',
        permissions: [pAdmin.feedback.adminRead]
      }
    ],
    permissions: [pAdmin.feedback.adminRead]
  },
  // {
  //   title: 'Announcement',
  //   route: '/announcement',
  //   icon: '',
  //   svgIcon: 'announcement',
  //   permissions: [],
  //   child: []
  // },
  {
    title: 'menu.newsboard.title',
    route: '/newsboard',
    icon: '',
    svgIcon: 'announcement',
    permissions: [pAdmin.announcement.read],
    child: [
      {
        title: 'menu.newsboard.announcement',
        route: ['/newsboard/announcement'],
        icon: '',
        svgIcon: 'announcement',
        permissions: [pAdmin.announcement.read]
      },
      {
        title: 'menu.newsboard.calendar',
        route: ['/newsboard/calendar'],
        icon: '',
        svgIcon: 'filter-date-alt',
        permissions: [pAdmin.calendar.read]
      }
    ]
  },
  {
    title: 'menu.development.title',
    route: '/development-partnership',
    icon: '',
    svgIcon: 'handshake',
    child: [
      {
        title: 'menu.development.partnership',
        route: ['/development-partnership/partnership'],
        icon: '',
        svgIcon: 'students',
        permissions: [pAdmin.developmentPartner.read]
      },
      {
        title: 'menu.development.project',
        route: ['/development-partnership/planning'],
        icon: '',
        svgIcon: 'students',
        permissions: [pAdmin.developmentPlan.read]
      }
    ],
    permissions: []
  },
  {
    title: 'menu.file_doc.title',
    route: '/file-management',
    icon: 'text_snippet',
    svgIcon: '',
    child: [],
    permissions: [pAdmin.fileDocument.read]
  },
  {
    title: 'menu.setting.title',
    route: '/setting',
    icon: '',
    svgIcon: 'setting',
    child: [
      // {
      //   title: 'Title',
      //   route: ['/settings/title'],
      //   icon: '',
      //   svgIcon: 'study',
      //   permissions: [pAdmin.title.read]
      // },
      {
        title: 'menu.setting.degree',
        route: ['/settings/degree'],
        icon: '',
        svgIcon: 'degree',
        permissions: [pAdmin.degree.read]
      },
      {
        title: 'menu.setting.sector',
        route: ['/settings/sector'],
        icon: '',
        svgIcon: 'sector',
        permissions: [pAdmin.sector.read]
      },
      {
        title: 'menu.setting.skill',
        route: ['/settings/skill'],
        icon: '',
        svgIcon: 'skill',
        permissions: [pAdmin.skill.read]
      },
      {
        title: 'academic_program.name',
        route: ['/settings/academic-program'],
        icon: '',
        svgIcon: 'school',
        permissions: [pAdmin.subject.read, pAdmin.academicProgram.s15Read]
      },
      {
        title: 'grading.name',
        route: ['/settings/grading'],
        icon: '',
        svgIcon: 'school',
        permissions: [pAdmin.scoreType.read, pAdmin.gradeScale.read]
      },
      {
        title: 'menu.setting.leave_type',
        route: ['/settings/leave-type'],
        icon: '',
        svgIcon: 'leave-type',
        permissions: [pAdmin.leaveType.read]
      },
      {
        title: 'sign.tvet',
        route: ['/settings/sign/tvet'],
        icon: '',
        svgIcon: 'edit-document',
        permissions: [pAdmin.certificateSignature.readTvet]
      },
      {
        title: 'sign.ministry',
        route: ['/settings/sign/ministry'],
        icon: '',
        svgIcon: 'edit-document',
        permissions: [pAdmin.certificateSignature.readMinistry]
      }
      // {
      //   title: 'Calendar',
      //   route: ['/settings/calendar'],
      //   icon: '',
      //   svgIcon: 'filter-date-alt',
      //   permissions: []
      // }
    ],
    permissions: []
  }
];
