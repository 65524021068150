<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav
      #sidenav
      class="example-sidenav"
      [class.large-width]="mobileQuery || isExpanded"
      [mode]="mobileQuery ? 'over' : 'side'"
      [opened]="!mobileQuery"
      [disableClose]="!mobileQuery"
    >
      <div class="main-logo" routerLink="/">
        <img src="assets/icons/mlvt-logo.svg" width="68" height="68" alt="logo-image" />
      </div>
      <mat-nav-list class="menu-list" id="list-nav">
        <div *ngFor="let m of menu">
          <mat-list-item
            #tooltip="matTooltip"
            [matTooltip]="!isExpanded ? (m.title | translate) : ''"
            matTooltipPosition="right"
            *ngIf="m.child.length <= 0 "
            [routerLink]="m.route"
            [routerLinkActive]="m.route ? 'active' : ''"
            [routerLinkActiveOptions]="{exact:false}"
          >
            <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate}}</span>
            <mat-icon mat-list-icon *ngIf="m.icon !== ''">{{ m.icon }}</mat-icon>
            <mat-icon
              mat-list-icon
              *ngIf="m.svgIcon !== '' "
              [svgIcon]="m.svgIcon"
              [class.svg-active]="m.route && route.url.includes(m.route)"
            >
            </mat-icon>
          </mat-list-item>

          <mat-expansion-panel
            *ngIf="m.child.length > 0 && isExpanded; else shrink"
            class="expansion-panel"
            [class.parents]="isChildActive(m.child)"
          >
            <mat-expansion-panel-header
              [class.active]="isChildActive(m.child)"
              #tooltipParent="matTooltip"
              [matTooltip]="!isExpanded ? (m.title | translate) : ''"
              matTooltipPosition="right"
            >
              <mat-icon mat-list-icon *ngIf="m.icon !== ''">{{ m.icon }}</mat-icon>
              <mat-icon mat-list-icon *ngIf="m.svgIcon !== ''" [svgIcon]="m.svgIcon"></mat-icon>

              <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate}}</span>
            </mat-expansion-panel-header>
            <mat-nav-list class="expansion-panel-body">
              <mat-list-item
                #tooltip="matTooltip"
                [matTooltip]="!isExpanded ? (c.title | translate) : ''"
                matTooltipPosition="right"
                *ngFor="let c of m.child"
                class=" {{ isExpanded ? 'expand' : 'no-expand' }}"
                [routerLink]="c.route"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact:false}"
              >
                <span class="menu-item-title">{{ c.title | translate}}</span>
                <mat-icon mat-list-icon *ngIf="c.icon !== '' && !isExpanded">{{ c.icon }}</mat-icon>
                <mat-icon mat-list-icon *ngIf="c.svgIcon !== '' && !isExpanded" [svgIcon]="c.svgIcon"></mat-icon>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <ng-template #shrink>
            <mat-list-item
              #tooltip="matTooltip"
              [matTooltip]="!isExpanded ? (m.title | translate) : ''"
              matTooltipPosition="right"
              *ngIf="m.child.length > 0 "
              [class.active]="isChildActive(m.child)"
              [matMenuTriggerFor]="narrow"
              [ngClass]="{'triangle': !isExpanded}"
            >
              <span class="menu-item-title" *ngIf="isExpanded">{{ m.title | translate}}</span>
              <mat-icon
                mat-list-icon
                *ngIf="m.svgIcon !== '' "
                [svgIcon]="m.svgIcon"
                [class.svg-active]="m.route && route.url.includes(m.route)"
              >
              </mat-icon>
              <mat-icon iconPositionEnd>chevron_right</mat-icon>
            </mat-list-item>

            <mat-menu #narrow="matMenu" [overlapTrigger]="true" backdropClass="nav-mat-menu">
              <mat-nav-list class="expansion-panel-body">
                <mat-list-item
                  *ngFor="let c of m.child"
                  [routerLink]="c.route"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact:false}"
                >
                  <span class="menu-item-title">{{ c.title | translate}}</span>
                </mat-list-item>
              </mat-nav-list>
            </mat-menu>
          </ng-template>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content
      [class.explicit-sm-ml]="!mobileQuery && !isExpanded"
      [class.explicit-lg-ml]=" !mobileQuery  && isExpanded"
      id="sidenav-content"
    >
      <div
        class="example-sidenav-content app-root-content"
        [class.nav-text-hidden]="!isExpanded"
        [class.nav-text-show]="isExpanded"
      >
        <mat-toolbar class="dashboard-toolbar">
          <header class="dashboard__header">
            <button class="icon menu-hamburger" mat-icon-button (click)="toggleSideNav()">
              <mat-icon svgIcon="menu-hamburger"></mat-icon>
            </button>
            <h1>{{activeAcademicYear}}</h1>
            <div class="header-action gap-8">
              <!-- languages -->
              <button
                class="languages"
                mat-button
                [matMenuTriggerFor]="dropMenu"
                aria-label="Example icon-button with a menu"
              >
                <div class="flex items-center gap-x-4">
                  <mat-icon svgIcon="{{browserLang === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                  <mat-icon iconPositionEnd>expand_more</mat-icon>
                </div>
              </button>
              <mat-menu #dropMenu="matMenu">
                <div>
                  <button
                    mat-menu-item
                    *ngFor="let lang of langs | keyvalue: originalOrder"
                    (click)="useLanguage(lang.key)"
                  >
                    <mat-icon svgIcon="{{lang.key === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                    <span class="mr-8">{{ lang.value }}</span>
                    <mat-icon *ngIf="browserLang === lang.key" svgIcon="check"></mat-icon>
                  </button>
                </div>
              </mat-menu>

              <div class="avtar">
                <button mat-button [matMenuTriggerFor]="beforeMenu" class="profile-menu-btn">
                  <img
                    mat-card-avatar
                    class="avtar-icon"
                    alt="avatar"
                    [src]="account?.profile_image ? (account?.profile_image | staticFile) + '?image_size=s' : '/assets/imgs/avatar-person.svg'"
                    onerror="this.src='/assets/imgs/avatar-person.svg'"
                  />
                  <mat-icon>expand_more</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before" backdropClass="hover-profile">
                  <div
                    mat-menu-item
                    class="profile-img"
                    [routerLink]="['/profile']"
                    routerLinkActive="router-link-active"
                  >
                    <img
                      mat-card-avatar
                      class="avatar-default"
                      alt="avatar"
                      [src]="account?.profile_image ? (account?.profile_image | staticFile) + '?image_size=s' : '/assets/imgs/avatar-person.svg'"
                      onerror="this.src='/assets/imgs/avatar-person.svg'"
                    />
                    <span class="ml-8">
                      <span class="name"> {{(account | staffFullName) || 'Unknown'}}</span>
                      <!-- <span class="role">{{account?.roles._id | staffRole}}</span> -->
                    </span>
                  </div>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [routerLink]="['/settings/history-log/account']">
                    <mat-icon svgIcon="log"></mat-icon>
                    {{'button.activity' | translate}}
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>{{'button.logout' | translate}}</button>
                </mat-menu>
              </div>
            </div>
          </header>
        </mat-toolbar>
        <section class="dash-row ">
          <router-outlet></router-outlet>
        </section>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
