import { Injectable } from '@angular/core';
import { Role } from '../models/enums/enumConstant';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { LocalStorageService } from './local-storage.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class RoleCheckerService {
  public m_roles: number | undefined;
  public roles = Role;

  private permissions: string[] | undefined;

  constructor(
    // private userDataService: UserDataService
    private localStorageService: LocalStorageService
  ) {
    // this.userDataService.currentUserData.subscribe(
    //     (e) => {
    //         this.m_roles = e?.roles?._id;
    //     }
    // );
  }

  // check(arr: number[]): boolean{
  //     if(arr.filter(e => e == this.m_roles).length > 0){
  //         return true;
  //     }
  //     return false;
  // }

  SetPermissions(permissions: string[]) {
    this.permissions = [];
    this.permissions = permissions;
  }

  GetPermissions(): string[] {
    if (this.permissions == undefined || this.permissions.length <= 0) {
      this.permissions = this.localStorageService.getArray(LocalStorageEnum.permissions);
    }
    return this.permissions;
  }
}
