import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'academic',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/academic-year/academic-year.module').then(m => m.AcademicYearModule)
      },
      {
        path: 'schools',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/schools/schools.module').then(m => m.SchoolsModule)
      },
      // {
      //   path: 'admission',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      // },
      // {
      //   path: 'class',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      // },
      {
        path: 'my-teaching',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'newsboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/newsboard/newsboard.module').then(m => m.NewsboardModule)
      },
      {
        path: 'settings/about-school',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/about-school/about-school.module').then(m => m.AboutSchoolModule)
      },
      {
        path: 'report/student',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./routes/student/student.module').then(m => m.StudentModule)
      },
      {
        path: 'classes',
        loadChildren: () => import('./routes/class/class.module').then(m => m.ClassModule)
      },
      {
        path: 'human-resource',
        loadChildren: () => import('./routes/lecturer/lecturer.module').then(m => m.LecturerModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-about-of-study/setting-about-of-study.module').then(m => m.SettingAboutOfStudyModule)
      },
      {
        path: 'settings/history-log',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-log-history/setting-log-history.module').then(m => m.SettingLogHistoryModule)
      },
      {
        path: 'settings/sign',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-certificate-sign/setting-certificate-sign.module').then(
            m => m.SettingCertificateSignModule
          )
      },
      {
        path: 'settings/sector',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting-sector/setting-sector.module').then(m => m.SettingSectorModule)
      },
      {
        path: 'development-partnership',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/development-partnership/development-partnership.module').then(
            m => m.DevelopmentPartnershipModule
          )
      },
      {
        path: 'feedback',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'file-management',
        loadChildren: () => import('./routes/file-document/file-document.module').then(m => m.FileDocumentModule)
      },
      {
        path: 'request-certificate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/request-certificate/request-certificate.module').then(m => m.RequestCertificateModule)
      },
      {
        path: 'settings/grading',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting-grading/setting-grading.module').then(m => m.SettingGradingModule)
      },
      {
        path: 'settings/academic-program',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/setting-academic-program/setting-academic-program.module').then(
            m => m.SettingAcademicProgramModule
          )
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
