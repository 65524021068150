<mat-dialog-content align="center">
  <div class="alert-icon-dialog">
    <img [src]="icon" alt="icon.svg" />
  </div>
  <h2 matDialogTitle>{{title | translate}}</h2>
  <span>{{message | translate}}</span>
</mat-dialog-content>
<mat-dialog-actions align="center" class="btn-action-dialog">
  <button mat-raised-button matDialogClose="cancel">
    <mat-icon>highlight_off</mat-icon>
    {{'button.cancel' | translate}}
  </button>
  <button *ngIf="button === 'delete'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    {{'button.okay' | translate}}
  </button>
  <button *ngIf="button === 'save'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>save</mat-icon>
    {{'button.save' | translate}}
  </button>
  <button *ngIf="button === 'confirm'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>check_circle_outline</mat-icon>
    {{'button.confirm' | translate}}
  </button>
</mat-dialog-actions>
