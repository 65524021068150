import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  status!: number;
  alertMessage: string = '';
  addMessage: string = 'The data has saved successfully';
  editMessage: string = 'The data has been updated successfully';
  deleteMessage: string = 'The data has deleted successfully';
  disableMessage: string = 'The data has closed successfully';
  changePasswordMessage: string = 'Password changed successfully!';
  resetPasswordMessage: string = 'Password reset successfully!';

  progress: number = 100;
  private currentIntervalId: number;
  duration: number = 5000;

  constructor(
    public sbRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: any
  ) {
    this.sbRef.afterOpened().subscribe(
      () => {
        this.runProgressBar();
      },
      error => console.error(error)
    );
  }

  ngOnInit(): void {
    this.status = this.data.status;

    switch (this.data.message) {
      case 'add':
        this.alertMessage = this.addMessage;
        break;
      case 'edit':
        this.alertMessage = this.editMessage;
        break;
      case 'delete':
        this.alertMessage = this.deleteMessage;
        break;
      case 'disable':
        this.alertMessage = this.disableMessage;
        break;
      case 'change-password':
        this.alertMessage = this.changePasswordMessage;
        break;
      case 'reset-password':
        this.alertMessage = this.resetPasswordMessage;
        break;
      default:
        this.alertMessage = this.data.message ?? 'Something went wrong';
        break;
    }
  }

  dismissWithAction() {
    this.cleanProgressBarInterval();
    this.sbRef.dismissWithAction();
  }

  /**
   * @param duration - in milliseconds
   */
  runProgressBar() {
    this.progress = 100;
    const step = 0.005;
    this.cleanProgressBarInterval();
    this.currentIntervalId = setInterval(() => {
      this.progress -= 100 * step;
      if (this.progress < 0) {
        this.cleanProgressBarInterval();
        this.sbRef.dismissWithAction();
      }
    }, this.duration * step) as unknown as number;
  }

  cleanProgressBarInterval() {
    clearInterval(this.currentIntervalId);
  }

  onHover(): void {
    clearInterval(this.currentIntervalId);
  }

  onLeave(): void {
    const step = 0.005;
    this.cleanProgressBarInterval();
    this.currentIntervalId = setInterval(() => {
      this.progress -= 100 * step;
      if (this.progress < 0) {
        this.cleanProgressBarInterval();
        this.sbRef.dismissWithAction();
      }
    }, this.duration * step) as unknown as number;
  }
}
