import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleId } from 'src/app/models/enums/enumConstant';

@Pipe({
  name: 'staffRole'
})
export class StaffRolePipe implements PipeTransform {
  transform(roles: number): string {
    let result: string = '';

    switch (roles) {
      case RoleId.ADMIN:
        result = Role.ADMIN;
        break;

      case RoleId.SCHOOL:
        result = Role.SCHOOL;
        break;

      case RoleId.TEACHER:
        result = Role.TEACHER;
        break;

      case RoleId.STUDENT:
        result = Role.STUDENT;
        break;

      case RoleId.FACULTY:
        result = Role.FACULTY;
        break;

      case RoleId.DEPARTMENT:
        result = Role.DEPARTMENT;
        break;

      // case RoleId.REVIEWER:
      //   result = Role.REVIEWER;
      //   break;

      default:
        result = '--/--';
        break;
    }
    return result;
  }
}
