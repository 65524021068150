export const pAdmin = {
  account: {
    read: 'a.account.read',
    write: 'a.account.write'
  },

  academicProgram: {
    s15Read: 'a.academicProgram.s15Read',
    s15Write: 'a.academicProgram.s15Write',
    s15Delete: 'a.academicProgram.s15Delete'
  },

  academicYear: {
    read: 'a.academicYear.read',
    write: 'a.academicYear.write',
    delete: 'a.academicYear.delete'
  },

  announcement: {
    read: 'a.announcement.read',
    write: 'a.announcement.write',
    delete: 'a.announcement.deleteAny'
  },

  attendanceList: {
    read: 'a.attendanceList.read'
  },

  calendar: {
    read: 'a.calendar.read',
    write: 'a.calendar.write',
    delete: 'a.calendar.delete',
    syncHoliday: 'a.calendar.syncHoliday'
  },

  certificateTemplate: {
    read: 'a.certificateTemplate.read',
    write: 'a.certificateTemplate.write',
    delete: 'a.certificateTemplate.delete'
  },

  classes: {
    read: 'a.class.read',
    readPromoteStudent: 'a.class.readPromoteStudent'
  },

  classEnrolment: {
    read: 'a.classEnrolment.read'
  },

  classSubject: {
    read: 'a.classSubject.read'
  },

  classFileShare: {
    read: 'a.classFileShare.read'
  },

  dashboard: {
    mainCount: 'a.dashboard.mainCount',
    studentAttend: 'a.dashboard.studentAttend',
    staffAttend: 'a.dashboard.staffAttend',
    requiredInput: 'a.dashboard.requiredInput'
  },

  degree: {
    read: 'a.degree.read',
    write: 'a.degree.write',
    delete: 'a.degree.delete'
  },
  developmentPartner: {
    read: 'a.developmentPartner.read',
    write: 'a.developmentPartner.write',
    delete: 'a.developmentPartner.deleteAny'
  },

  developmentPlan: {
    read: 'a.developmentPlan.read',
    write: 'a.developmentPlan.write',
    delete: 'a.developmentPlan.deleteAny'
  },

  feedback: {
    adminRead: 'a.feedback.adminRead'
  },

  fileDocument: {
    read: 'a.fileDocument.read',
    write: 'a.fileDocument.write',
    update: 'a.fileDocument.update',
    delete: 'a.fileDocument.delete'
  },

  inventoryItem: {
    read: 'a.inventoryItem.read'
  },

  leaveType: {
    read: 'a.leaveType.read',
    write: 'a.leaveType.write',
    delete: 'a.leaveType.delete'
  },

  logAdmin: {
    read: 'a.logAdmin.read'
  },

  logUser: {
    read: 'a.logUser.read'
  },

  report: {
    studentBySkill: 'a.report.studentBySkill',
    shortCourseBySkill: 'a.report.shortCourseBySkill',
    graduatedStudent: 'a.report.graduatedStudent',
    longCoursePassFailed: 'a.report.longCoursePassFailed',
    certificateRequest: 'a.report.certificateRequest',
    developmentPartnerData: 'a.report.developmentPartnerData'
  },

  school: {
    read: 'a.school.read',
    write: 'a.school.write',
    delete: 'a.school.delete'
  },

  staff: {
    read: 'a.staff.read',
    write: 'a.staff.write',
    setActive: 'a.staff.setActive',
    resetPwd: 'a.staff.resetPwd'
  },

  studentOccupation: {
    read: 'a.studentOccupation.read',
    write: 'a.studentOccupation.write'
  },

  studentIntership: {
    write: 'a.studentIntership.write',
    read: 'a.studentIntership.read',
    delete: 'a.studentIntership.delete',
  },

  scheduleList: {
    read: 'a.scheduleList.read'
  },

  score: {
    read: 'a.score.read'
  },

  schoolStaff: {
    read: 'a.schoolStaff.read',
    resetPwd: 'a.schoolStaff.resetPwd',
    setActive: 'a.schoolStaff.setActive',
    readSchedule: 'a.schoolStaff.readSchedule',
    class: 'a.schoolStaff.class'
  },

  student: {
    read: 'a.student.read',
    graduated: 'a.student.graduated',
    readClassEnrolment: 'a.student.readClassEnrolment'
  },

  sector: {
    read: 'a.sector.read',
    write: 'a.sector.write',
    delete: 'a.sector.delete'
  },

  skill: {
    read: 'a.skill.read',
    write: 'a.skill.write',
    delete: 'a.skill.delete'
  },

  subject: {
    read: 'a.subject.read',
    write: 'a.subject.write',
    delete: 'a.subject.delete'
  },

  title: {
    read: 'a.title.read',
    write: 'a.title.write',
    delete: 'a.title.delete'
  },

  filter: {
    gender: 'a.report.gender',
    passFail: 'a.report.passFail',
    staffAgeGender: 'a.report.staffAgeGender',
    staffAttendance: 's.report.staffAttendance',
    studentAttendance: 's.report.studentAttendance',
    studentGenderProgram: 'a.report.studentGenderProgram',
    studentGenderGeneration: 'a.report.studentGenderGeneration'
  },

  classDataApproval: {
    approve: 's.classDataApproval.approve',
    request: 's.classDataApproval.request',
    delete: 's.classDataApproval.delete'
  },

  requestCertificate: {
    read: 'a.requestCertificate.read',
    officerDepartmentRead: 'a.OfficerDepartment.read',
    officerDepartmentApproval: 'a.OfficerDepartment.approval',
    generalDepartmentRead: 'a.generalDepartment.read',
    generalDepartmentApproval: 'a.generalDepartment.approval',
    ministryRead: 'a.ministryRead.read',
    ministryApproval: 'a.ministryApproval.approval',
    controlSchoolDepartment: 'a.controlSchoolDepartment.read',
    controlSchoolDepartmentApproval: 'a.controlSchoolDepartment.approval'
  },

  studentCertificate: {
    read: 'a.studentCertificate.read',
    justRead: 'a.studentCertificate.justRead',
    write: 'a.studentCertificate.write',
    print: 'a.studentCertificate.print',
    reachCandidate: 'a.studentCertificate.reachCandidate'
  },

  scoreType: {
    read: 'a.scoreType.read',
    write: 'a.scoreType.write',
    delete: 'a.scoreType.delete'
  },
  gradeScale: {
    read: 'a.gradeScale.read',
    write: 'a.gradeScale.write'
  },

  certificateSignature: {
    readSchool: 'a.certificateSignature.readSchool',
    writeSchool: 'a.certificateSignature.writeSchool',
    readTvet: 'a.certificateSignature.readTvet',
    writeTvet: 'a.certificateSignature.writeTvet',
    readMinistry: 'a.certificateSignature.readMinistry',
    writeMinistry: 'a.certificateSignature.writeMinistry'
  }
};
